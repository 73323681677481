<template src="./index.html" />

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BFormSelect, BCard, BTable,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import VNnum2words from 'vn-num2words'
import { vn } from 'flatpickr/dist/l10n/vn.js'
import flatPickr from 'vue-flatpickr-component'
import VueHtml2pdf from 'vue-html2pdf'
import vSelect from 'vue-select'
import PdfContent from '@/views/finance/receipt/PdfContent'
import Ripple from 'vue-ripple-directive'
import { GENDERS } from '@/const/status'
import History from '@/views/finance/receipt/History'

export default {
  name: 'Index',
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    VueGoodTable,
    flatPickr,
    VueHtml2pdf,
    vSelect,
    BTable,
    PdfContent,
    History
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: getUser(),
      optionsStatus: [],
      optOrganizations: [],
      required,
      serverParams: {
        receiptCode: null,
        itemsPerPage: 10,
        currentPage: 1,

      },
      optionsReceipt: [],
      filter: {
        code_student: null,
        day: '',
        receipt_id: null,
      },
      currentReceipt: {
        course_semester_id: null,
        student_classes_id: null,
        reason: null,
        amount: null,
        amount_word: null,
        total: null,
        total_exemption: null,
        code: null,
        id: null,
        last_name: null,
        first_name: null,
        gender: null,
        birthday: null,
        birth_place: null,
        class_name: null,
        major_name: null,
        course_name: null,
        policies_name: null,
        revenue_name: null,
        collecter_id: null,
        collection_time: null,
        status: 1,
      },
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: vn,
        // minDate: 'today',
      },
      revenue_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'subject_name', label: 'Học phần' },
        { key: 'total_credit_num', label: 'Số tín chỉ' },
        { key: 'amount', label: 'Số tiền' },
      ],
      revenue_items: [],
      exemption_fields: [
        { key: 'semester_name', label: 'Kỳ học' },
        { key: 'revenue_name', label: 'Loại miễn giảm' },
        { key: 'amount', label: 'Số tiền' },
      ],
      controlValue: {
        showLayout: false,
        floatLayout: true,
        enableDownload: false,
        previewModal: true,
        paginateElementsByHeight: 1200,
        manualPagination: false,
        filename: 'PhieuThuHocPhi',
        pdfQuality: 2,
        pdfFormat: 'a5',
        pdfOrientation: 'landscape',
        pdfContentWidth: '800px',
      },
      exemption_items: [],
      itemPrint: {
        name: '',
        address: '',
        content: '',
        amount: '',
        amount_word: '',
        date: '',
      },
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      listReceipt: 'receipt/listReceipt',
      listStudentInfo: 'receipt/listStudentInfo',
      listRevenues: 'receipt/listRevenues',
      listExemptions: 'receipt/listExemptions',
    }),
    creatable() {
      return true
    },
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: 'PhieuThu.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      }
    },
  },
  async created() {
    // init data FROM API
    const { portalProgrammeId } = this.user
    const { studentClassId } = this.user.programmes.find(item => item.id === portalProgrammeId)
    await this.getListReceipt({ student_class_id: studentClassId })
    if (this.listReceipt.length > 0) {
      this.optionsReceipt = this.listReceipt.map(item => ({ value: item.id, label: item.reason }))
    }
    this.filter.code_student = this.user.username
  },
  methods: {
    ...mapActions({
      getReceipt: 'receipt/getReceipt',
      getListReceipt: 'receipt/getReceiptByStudentClass',
    }),
    // handle even

    async getData() {

    },

    async onChangeReceipt(receiptId) {

    },

    async getReceiptByCode() {
      if (!this.filter.code_student || !this.filter.receipt_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mã số sinh viên hoặc phiếu thu đang để trống',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } else {
        await this.getReceipt(this.filter)
        if (this.listStudentInfo) {
          this.currentReceipt = {
            ...this.currentReceipt,
            ...this.listStudentInfo[0],
          }
          this.currentReceipt.amount_word = this.getAmountWord(
            this.currentReceipt.amount,
          )
          this.revenue_items = this.listRevenues
          this.exemption_items = this.listExemptions
          if (this.currentReceipt.collection_time != null) {
            this.filter.day = this.currentReceipt.collection_time
          }
        }
      }
    },

    getAmountWord(amount) {
      if (amount !== null) {
        // eslint-disable-next-line camelcase
        const amount_word = VNnum2words(amount)
        return `${
          amount_word.charAt(0).toUpperCase() + amount_word.slice(1)
        } đồng`
      }
      return null
    },

    getGender(id) {
      if (id !== null) {
        const rs = GENDERS.find(gender => gender.value === id)
        return (rs === undefined ? 'Không xác định' : rs.label)
      }
      return null
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    async onPrint() {
      this.isLoading = true
      try {
        this.itemPrint.name = getUser().fullName
        this.itemPrint.address = getUser().department_name
        this.itemPrint.content = `Thu tiền ${this.currentReceipt.reason.toLowerCase()} của sinh viên ${this.currentReceipt.last_name} ${this.currentReceipt.first_name} - Lớp ${this.currentReceipt.course_name} ${this.currentReceipt.class_name}`
        this.itemPrint.amount = new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(this.currentReceipt.amount)
        this.itemPrint.amount_word = this.getAmountWord(this.currentReceipt.amount)
        this.itemPrint.date = `Ngày ${this.currentReceipt.collection_time.substring(8, 10)} tháng ${this.currentReceipt.collection_time.substring(5, 7)} năm ${this.currentReceipt.collection_time.substring(0, 4)}`
        this.$refs.html2PdfRef.generatePdf()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    resetForm() {
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
